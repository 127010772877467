import React, { ReactElement, useEffect } from 'react'

import {
  BottomPageCTASection,
  ComparisonTable,
  EmailCapture,
  EmphasisLinebreakText,
  Hero,
  HomepageLayout,
  Partnerships,
  TrustpilotCarousel,
  TrustPilotMicroCombo,
  WeeklyRecipes,
} from '@mindfulchefuk/features/Homepage/components'

import {
  HomepagePromotionalBanner,
  MultiCardGroup,
  ProductAnnouncementModal,
} from '@mindfulchefuk/features/CMS/components'

import { Box } from '@mindfulchefuk/design-system'
import { useApplyVoucher } from '@mindfulchefuk/features/Basket'
import { useCMSHomepageVariant } from '@mindfulchefuk/features/CMS/hooks/useCMSHomepageVariant'
import { HomepageSectionContainer } from '@mindfulchefuk/features/Homepage/components/HomepageSectionContainer'
import { TOTAL_RECIPES_AVAILABLE_PER_WEEK } from '@mindfulchefuk/constants'
import { REFERRER_PATH_KEY } from '@mindfulchefuk/hooks/useReferrer'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'
import { THomepage } from './interfaces'

type THomepageProps = {
  voucherCode?: string
}

export const Homepage = ({
  voucherCode,
  heroImageDesktop,
  heroImageMobile,
  heroTrustpilotBannerDesktop,
  heroTrustpilotBannerMobile,
  heroRecipeBoxImage,
}: THomepageProps & Partial<THomepage>): ReactElement => {
  useApplyVoucher(voucherCode)
  const {
    howItWorks,
    trustPilotCarouselTitle,
    oneFeedsTwoEmphasisText,
    oneFeedsTwoText,
  } = useCMSHomepageVariant()

  useEffect(() => {
    const pageSource = window.sessionStorage.getItem(REFERRER_PATH_KEY)

    trackAnalyticsEvent({
      name: 'homepage viewed',
      data: { rawPageSource: pageSource },
    })
  }, [])

  return (
    <>
      <HomepageLayout>
        <Box color="aubergine" id="homepage-whole-page">
          <Hero
            heroImageMobile={heroImageMobile}
            heroImageDesktop={heroImageDesktop}
            trustpilotHeroImage={heroTrustpilotBannerDesktop}
            trustpilotHeroImageMobile={heroTrustpilotBannerMobile}
            recipeBoxImage={heroRecipeBoxImage}
          />
          <ComparisonTable />

          <TrustpilotCarousel
            ctaLocation="homepage trustpilot CTA"
            title={trustPilotCarouselTitle}
          />

          <HomepagePromotionalBanner />

          <WeeklyRecipes
            title="There's something for everyone"
            titleEmphasis="something for everyone"
            subtitle={`Choose from ${TOTAL_RECIPES_AVAILABLE_PER_WEEK} delicious recipes each week.`}
            subtitleEmphasis={`${TOTAL_RECIPES_AVAILABLE_PER_WEEK} delicious`}
            ctaLocation="homepage weekly recipe CTA"
          />

          <MultiCardGroup
            data={howItWorks}
            ctaLocation="homepage how it works CTA"
            useCms
            id="homepage-how-it-works"
          />

          <HomepageSectionContainer
            bg="salt"
            px={24}
            id="homepage-one-feeds-two-text-container"
          >
            <EmphasisLinebreakText
              as="h2"
              mx="auto"
              textAlign="center"
              maxWidth="500px"
              text={oneFeedsTwoText}
              emphasisText={oneFeedsTwoEmphasisText}
              variant="heading-md"
              emphasisVariant="heading-lg"
              id="homepage-one-feeds-two-text"
            />
          </HomepageSectionContainer>

          <TrustPilotMicroCombo id="homepage-trustpilot-microcombo" />

          <Partnerships />

          <BottomPageCTASection ctaLocation="homepage bottom of the page CTA" />

          <EmailCapture />
        </Box>
      </HomepageLayout>

      <ProductAnnouncementModal page="page-homepage" />
    </>
  )
}
