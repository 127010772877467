import React, { ReactElement } from 'react'
import { Box, ColorToken, Text } from '@mindfulchefuk/design-system'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { EmailCaptureForm } from '@mindfulchefuk/features/Homepage/components/EmailCapture/EmailCaptureForm'
import {
  createNewsletterContact,
  NewsletterContactFormSource,
} from '@mindfulchefuk/features/Homepage/services/createNewsletterContact'
import { EmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/components/EmphasisLinebreakText'
import { HomepageSectionContainer } from '@mindfulchefuk/features/Homepage/components/HomepageSectionContainer'
import { genericDiscountCopy } from '@mindfulchefuk/config/voucherConfig'

export const EmailCapture = ({
  color = 'aubergine',
  isBBCGoodFood = false,
  source = 'MC_homepage',
}: {
  color?: ColorToken
  isBBCGoodFood?: boolean
  source?: NewsletterContactFormSource
}): ReactElement => (
  <HomepageSectionContainer
    bg={isBBCGoodFood ? 'aubergine' : 'aubergine-50'}
    data-testid="email-capture"
    id="homepage-email-capture-section"
  >
    <Box color={color} maxWidth="680px" px={24} mx="auto" textAlign="center">
      <EmphasisLinebreakText
        text={genericDiscountCopy}
        emphasisText="Save 25%"
        variant={{ base: 'heading-sm', sm: 'heading-md' }}
        emphasisVariant={{ base: 'heading-md', sm: 'heading-lg' }}
        id="homepage-email-capture-header"
      />

      <Text
        as="p"
        variant={{ base: 'body-md', sm: 'body-lg' }}
        mt={16}
        mb={12}
        id="homepage-email-capture-newsletter-copy"
      >
        Get delicious discounts, inspiration, and recipes by signing up to our
        newsletter.
      </Text>
      <EmailCaptureForm
        isBBCGoodFood={isBBCGoodFood}
        source={source}
        submitHandler={(formValues) => {
          logEvent({
            category: ANALYTICS_CATEGORIES.homepage,
            action: 'Email capture CTA clicked',
            label: 'Homepage',
          })
          return createNewsletterContact(formValues)
        }}
      />
    </Box>
  </HomepageSectionContainer>
)
