import React, { ReactElement } from 'react'
import { Box, Image } from '@mindfulchefuk/design-system'

export type THeroStaticImage = {
  heroImage: string
  imageWidth: number
  imageHeight: number
}

export const HeroStaticImage = ({
  heroImage,
  imageHeight,
  imageWidth,
}: THeroStaticImage): ReactElement => (
  <Box
    id="hero-image-container"
    maxHeight={768}
    overflow="hidden"
    width={{ md: '50%' }}
  >
    <Image
      height={imageHeight}
      id="homepage-hero-image"
      src={heroImage}
      width={imageWidth}
    />
  </Box>
)
