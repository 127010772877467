import React, { ReactElement } from 'react'
import { Box, Icon, Text } from '@mindfulchefuk/design-system'

export const WhyUsPointer = (): ReactElement => (
  <Box textAlign="center" display="inline-block" color="aubergine">
    <Text
      id="homepage-comparison-table-why-us-callout-text"
      mb={4}
      variant={{ base: 'body-md-500', sm: 'body-xxl-500' }}
    >
      Why us?
    </Text>
    <Icon
      mx="auto"
      size={32}
      strokeWidth={{ base: '.75px', sm: '1px' }}
      transform="rotate(-90deg)"
      type="arrow-curved"
    />
  </Box>
)
