import { useDispatch } from 'react-redux'
import mealPlanConfig, { TMealPlan } from '@mindfulchefuk/config/mealPlanConfig'
import { useEffect, useMemo } from 'react'
import { PortionCount } from '@mindfulchefuk/config/portionCountConfig'
import {
  setExcludedFoodGroups,
  setMealPlan,
} from '@mindfulchefuk/actions/recipesActions'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import { setPortionCount } from '@mindfulchefuk/actions/basketActions'
import { useAvailableDates } from '@mindfulchefuk/features/Operations/hooks/useAvailableDates'
import { useGetJustRecipes } from './useGetJustRecipes'
import { useGetRecipeRecommendations } from './useGetRecipeRecommendations'

interface Props {
  mealPlan: TMealPlan
  portionCount: PortionCount
}

export const useGetHomepageRecipes = ({ mealPlan, portionCount }: Props) => {
  const customerId = getCustomerId()

  const {
    recipes: { firstAvailableDeliveryDate },
  } = useAvailableDates()

  const dispatch = useDispatch()
  const { excludedFoodGroups } = useMemo(
    () =>
      mealPlanConfig.find((mealPlanItem) => mealPlanItem.value === mealPlan),
    [mealPlan]
  )

  // Update the meal plan, excludedFoodGroups and portionCount in the store
  useEffect(() => {
    dispatch(setMealPlan(mealPlan))
    dispatch(setExcludedFoodGroups(excludedFoodGroups))
    dispatch(setPortionCount(portionCount))
  }, [dispatch, excludedFoodGroups, mealPlan, portionCount])

  const { data: recipeRecommendations = [] } = useGetRecipeRecommendations({
    date: firstAvailableDeliveryDate,
    excludedFoodGroups,
    mealPlan,
    portionCount,
    customerId,
  })

  const recipeIds = useMemo(
    () => recipeRecommendations?.map((recipe) => recipe.recipeId),
    [recipeRecommendations]
  )

  return useGetJustRecipes(
    { recipeIds, portionCount },
    { enabled: !!recipeRecommendations.length }
  )
}
