import React, { ReactElement } from 'react'
import { Box, Container, space, Text } from '@mindfulchefuk/design-system'
import { HeaderWithLogos } from '@mindfulchefuk/features/Homepage/components/ComparisonTable/HeaderWithLogos'
import { RowsGrid } from '@mindfulchefuk/features/Homepage/components/ComparisonTable/RowsGrid'

export const ComparisonTable = (): ReactElement => {
  return (
    <Box
      id="homepage-comparison-table-section"
      as="section"
      position="relative"
    >
      <Box paddingTop={space[48]} paddingBottom={space[28]}>
        <Text
          variant={{ base: 'heading-sm', md: 'heading-md' }}
          as="h1"
          textAlign="center"
          mb={16}
          px={36}
          id="homepage-comparison-table-header"
        >
          We believe the quality of the food (really) matters
        </Text>

        <Text
          variant={{ base: 'body-md', sm: 'body-lg' }}
          as="p"
          textAlign="center"
          mb={32}
          px={36}
          id="homepage-comparison-table-subheading"
        >
          That&apos;s why 66% of our customers have switched from another recipe
          box company
        </Text>

        <Container position="relative" top={{ xl: 24 }} maxWidth="800px">
          <HeaderWithLogos />
        </Container>
      </Box>

      <Box
        backgroundColor="aubergine"
        pt={48}
        pb={10}
        id="homepage-comparison-table-container"
      >
        <Container maxWidth="800px">
          <RowsGrid />
        </Container>
      </Box>
    </Box>
  )
}
