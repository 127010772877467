import React, { useState, useMemo, ReactElement } from 'react'
import { SwiperSlide } from 'swiper/react'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { JustRecipe } from '@mindfulchefuk/features/Recipes/interfaces'

import { WeeklyRecipeCard } from '@mindfulchefuk/features/Homepage/components/WeeklyRecipes/WeeklyRecipeCard'
import { useIsBBCGoodFood } from '@mindfulchefuk/features/Homepage/utils/isHomepageVariant'
import { Carousel, CarouselProvider } from '@mindfulchefuk/design-system'

export const WeeklyRecipeCarousel = ({
  recipes,
}: {
  recipes: JustRecipe[]
}): ReactElement => {
  const [lastAction, setLastAction] = useState<'click' | 'swipe' | ''>('')
  const isBBCGoodFood = useIsBBCGoodFood()

  if (isBBCGoodFood) {
    recipes.sort(({ badges }) => (badges.includes('bbc_good_food') ? -1 : 1))
  }

  const swiperSlides = useMemo(
    () =>
      recipes.map((recipe) => (
        <SwiperSlide
          key={recipe.id}
          className="swiper-weekly-recipe-slide"
          id="homepage-weekly-recipes-carousel"
        >
          <WeeklyRecipeCard
            title={recipe.title}
            imageUrl={recipe.imageUrl}
            isBBCGoodFood={
              isBBCGoodFood && recipe.badges.includes('bbc_good_food')
            }
            isVegan={recipe.foodGroup === 'Vegan'}
            calories={recipe.macros.macrosPerPerson.calories as number}
            cookingTime={recipe.cookingTime}
          />
        </SwiperSlide>
      )),
    [recipes, isBBCGoodFood]
  )

  const loopMode = !isBBCGoodFood

  return (
    <CarouselProvider>
      <Carousel
        slides={swiperSlides}
        loop={loopMode}
        slidesPerGroup={1}
        onTouchStart={() => setLastAction('swipe')}
        slidesPerGroupAuto
        slidesOffsetBefore={loopMode ? 0 : 24}
        slidesOffsetAfter={loopMode ? 0 : 24}
        spaceBetween={16}
        showButtonsOnIndexViewed
        watchSlidesProgress
        breakpoints={{
          768: {
            slidesPerGroup: 3,
          },
          1280: {
            slidesOffsetBefore: loopMode ? 0 : 90,
            slidesOffsetAfter: loopMode ? 0 : 90,
          },
        }}
        onPreviousButtonClick={() => {
          setLastAction('click')
          logEvent({
            category: ANALYTICS_CATEGORIES.homepage,
            action: 'Recipe Carousel Swipe',
            label: lastAction === 'swipe' ? 'Swipe left' : 'Left arrow clicked',
          })
        }}
        onNextButtonClick={() => {
          setLastAction('click')
          logEvent({
            category: ANALYTICS_CATEGORIES.homepage,
            action: 'Recipe Carousel Swipe',
            label:
              lastAction === 'swipe' ? 'Swipe right' : 'Right arrow clicked',
          })
        }}
      />
    </CarouselProvider>
  )
}
