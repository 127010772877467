import React, { ReactElement } from 'react'
import { Box, BoxProps, Text } from '@mindfulchefuk/design-system'
import { IconRoundel } from '@mindfulchefuk/features/Homepage/components/IconRoundel'

type TCompetitorNames = 'greenChef' | 'gousto' | 'helloFresh' | string

export type TRow = {
  text: string
  checked: { [key in TCompetitorNames]: boolean }
  rowNumber: number
}

type TIconProps = {
  brand: string
  text: string
}

const Tick = (props?: BoxProps & TIconProps): ReactElement => {
  const { brand, text } = props
  return (
    <Box py={12} {...props} aria-label={`${text} is offered by ${brand}`}>
      <IconRoundel
        type="tick"
        background="broccoli"
        mx="auto"
        size={[28, 40]}
      />
    </Box>
  )
}

const Cross = ({ text, brand }: TIconProps): ReactElement => (
  <Box py={12} aria-label={`${text} is not offered by ${brand}`}>
    <IconRoundel
      type="thick-cross"
      background="tomato-300"
      mx="auto"
      size={[28, 40]}
    />
  </Box>
)

export const Row = ({ checked, text, rowNumber }: TRow): ReactElement => (
  <>
    <Box gridColumn="1">
      <Text
        maxWidth={256}
        variant={{ base: 'body-sm', sm: 'body-lg' }}
        textAlign="left"
        id={`homepage-comparison-table-row-${rowNumber}-text`}
      >
        {text}
      </Text>
    </Box>
    <Tick gridColumn={2} brand="MindfulChef" text={text} />
    {/** MC always has a tick because we're the best */}
    {checked.helloFresh ? (
      <Tick gridColumn={3} brand="HelloFresh" text={text} />
    ) : (
      <Cross brand="HelloFresh" text={text} />
    )}
    {checked.gousto ? (
      <Tick gridColumn={4} brand="Gousto" text={text} />
    ) : (
      <Cross brand="Gousto" text={text} />
    )}
    {checked.greenChef ? (
      <Tick gridColumn={5} brand="Green Chef" text={text} />
    ) : (
      <Cross brand="Green Chef" text={text} />
    )}
  </>
)
