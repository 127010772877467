import React, { ReactElement } from 'react'
import {
  Box,
  CONTAINER_MAX_WIDTH,
  ColorToken,
  Flex,
} from '@mindfulchefuk/design-system'
import { PlateCallout } from '@mindfulchefuk/features/Homepage/components/Hero/PlateCallout'
import { Plate } from '@mindfulchefuk/features/Homepage/components/Hero/Plate'
import { CMSPlates } from '@mindfulchefuk/features/CMS/interfaces'
import { layers } from './config'

export const HeroPlateSection = ({
  bottomColor = 'salt',
  plates,
}: {
  bottomColor?: ColorToken
  plates: CMSPlates
}): ReactElement => {
  return (
    <Box position="relative" data-testid="plates-section">
      <Box
        position="absolute"
        width="100%"
        top={0}
        height={{ base: '93%', sm: '50%' }}
        backgroundColor="aubergine"
        zIndex={layers.topSection}
      />

      <Box
        position="absolute"
        width="100%"
        height={{ base: '7%', sm: '50%' }}
        bottom={0}
        backgroundColor={bottomColor}
        zIndex={layers.bottomSection}
      />
      <Box
        px="10%"
        mx="auto"
        maxWidth={CONTAINER_MAX_WIDTH}
        position="relative"
      >
        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent="center"
          paddingBottom={{ base: 0, sm: 40 }}
          margin="0 auto"
          maxWidth="fit-content"
          position="relative"
          minHeight={{
            base: '230px',
            sm: '285px',
            md: '285px',
            lg: '365px',
          }}
        >
          {plates.onePersonImage && (
            <Plate
              key={1}
              portionCount={1}
              imageUrl={plates.onePersonImage.path}
              altText={plates.onePersonImage.altText}
              href={plates.onePersonUrl}
              layer={0}
              id="homepage-1-person-plate-image"
            />
          )}
          {plates.twoPersonImage && (
            <Plate
              key={2}
              portionCount={2}
              imageUrl={plates.twoPersonImage.path}
              altText={plates.twoPersonImage.altText}
              href={plates.twoPersonUrl}
              layer={1}
              id="homepage-2-person-plate-image"
            />
          )}
          {plates.fourPersonImage && (
            <Plate
              key={4}
              portionCount={4}
              imageUrl={plates.fourPersonImage.path}
              altText={plates.fourPersonImage.altText}
              href={plates.fourPersonUrl}
              layer={2}
              id="homepage-4-person-plate-image"
            />
          )}
          {plates.calloutText && <PlateCallout text={plates.calloutText} />}
        </Flex>
      </Box>
    </Box>
  )
}
