import React, { ReactElement } from 'react'
import { Box, Grid } from '@mindfulchefuk/design-system'

import StackedSmallLogo from '@mindfulchefuk/design-system/Logos/StackedSmall'
import { GoustoLogo } from '@mindfulchefuk/features/Homepage/components/logos/GoustoLogo'
import { HelloFreshLogo } from '@mindfulchefuk/features/Homepage/components/logos/HelloFreshLogo'
import { MindfulChefComparisonTableLogo } from '@mindfulchefuk/features/Homepage/components/logos/MindfulChefLogo'
import { GreenChefLogo } from '@mindfulchefuk/features/Homepage/components/logos/GreenChefLogo'
import { WhyUsPointer } from '@mindfulchefuk/features/Homepage/components/ComparisonTable/WhyUsPointer'

export const HeaderWithLogos = (): ReactElement => (
  <Grid
    templateColumns="2fr 1fr 1fr 1fr 1fr"
    columnGap={[8, 16]}
    alignItems="center"
    justifyContent="center"
  >
    <Box position="relative" left="20%" bottom={14}>
      <WhyUsPointer />
    </Box>
    <StackedSmallLogo
      display={['inline-block', 'none']}
      id="homepage-mobile-mc-logo"
      maxWidth={40}
      mx="auto"
      width="100%"
    />
    <MindfulChefComparisonTableLogo
      display={['none', 'inline-block']}
      id="homepage-desktop-mc-logo"
      mx="auto"
      width={112}
    />
    <HelloFreshLogo id="homepage-comparison-table-hello-fresh-logo" />
    <GoustoLogo
      id="homepage-comparison-table-gousto-logo"
      mx="auto"
      width={[40, 112]}
    />
    <GreenChefLogo
      id="homepage-comparison-table-green-chef-logo"
      mx="auto"
      width={[32, 112]}
    />
  </Grid>
)
