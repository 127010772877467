import React, { ReactElement, ReactNode } from 'react'
import { Flex, Icon, Text } from '@mindfulchefuk/design-system'
import { IconType } from '@mindfulchefuk/design-system/Icon/svgs'

type TRecipeStat = {
  children: ReactNode
  'data-testid'?: string
  icon: IconType
}

export const RecipeStat = ({
  icon,
  children,
  'data-testid': testId,
}: TRecipeStat): ReactElement => (
  <Flex align="center" mr={8} py={8} data-testid={testId}>
    <Icon type={icon} size={20} mr={4} flexShrink={0} />
    <Text variant="body-sm-500-caps">{children}</Text>
  </Flex>
)
