import React, { ReactElement } from 'react'
import { AnimatePresence, motion } from 'motion/react'
import { useRouter } from 'next/router'

import { Box, Container, Text } from '@mindfulchefuk/design-system'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'

import CTA from '@mindfulchefuk/features/Homepage/components/CTA/CTA'
import { useGetHomepageRecipes } from '@mindfulchefuk/features/Recipes/hooks/useGetHomepageRecipes'
import { WeeklyRecipeCarousel } from '@mindfulchefuk/features/Homepage/components/WeeklyRecipes/WeeklyRecipesCarousel'
import { EmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/components/EmphasisLinebreakText'
import { CTALocation } from '@mindfulchefuk/analytics'
import { HomepageSectionContainer } from '@mindfulchefuk/features/Homepage/components/HomepageSectionContainer'

export type TWeeklyRecipes = {
  title: string
  titleEmphasis?: string
  subtitle?: string
  subtitleEmphasis?: string
  ctaLocation?: CTALocation
}

export const WeeklyRecipes = ({
  ctaLocation,
  title,
  titleEmphasis,
  subtitle,
  subtitleEmphasis,
}: TWeeklyRecipes): ReactElement => {
  const { asPath } = useRouter()

  const isVeganSEOPage = asPath === '/vegan-recipe-boxes'

  const { data: recipes = [] } = useGetHomepageRecipes({
    mealPlan: isVeganSEOPage ? 'Plant-Based' : 'Balanced',
    portionCount: 2,
  })

  return (
    <HomepageSectionContainer
      as="section"
      bg="salt"
      data-testid="weekly-recipes"
      id="homepage-weekly-recipes-section"
    >
      <Container maxWidth="800px">
        <EmphasisLinebreakText
          as="h2"
          variant="heading-md"
          textAlign="center"
          mb={12}
          text={title}
          emphasisText={titleEmphasis}
          emphasisVariant="heading-lg"
          id="homepage-weekly-recipes-heading"
        />

        {subtitle && (
          <EmphasisLinebreakText
            as="p"
            variant={{ base: 'body-md', sm: 'body-lg' }}
            textAlign="center"
            text={subtitle}
            emphasisText={subtitleEmphasis}
            emphasisVariant={{ base: 'body-md-500', sm: 'body-lg-500' }}
            id="homepage-weekly-recipes-subtitle"
          />
        )}
      </Container>
      <Box py={12} minHeight={{ base: '285px', md: '320px' }}>
        {recipes.length ? (
          <AnimatePresence>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <WeeklyRecipeCarousel recipes={recipes} />
            </motion.div>
          </AnimatePresence>
        ) : null}
      </Box>
      <Box mt={12} textAlign="center" maxWidth={256} mx="auto">
        <CTA
          analytics={{
            category: ANALYTICS_CATEGORIES.homepage,
            action: 'Recipe Carousel CTA clicked',
            label: 'Choose recipe button clicked',
          }}
          source={ctaLocation}
          showCopy={false}
          id="homepage-weekly-recipes-CTA"
        />
        <Text
          as="p"
          mt={12}
          px={12}
          variant={{ base: 'body-sm', md: 'body-md' }}
          id="homepage-weekly-recipes-flexible-subscription-text"
        >
          Fully flexible subscription, skip or pause anytime
        </Text>
      </Box>
    </HomepageSectionContainer>
  )
}
