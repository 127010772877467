import React, { ReactElement } from 'react'
import { Box, CONTAINER_MAX_WIDTH, Text } from '@mindfulchefuk/design-system'
import { layers } from '@mindfulchefuk/features/Homepage/components/Hero/config'
import { EmphasisLinebreakText } from '@mindfulchefuk/features/Homepage/components/EmphasisLinebreakText'

export type THeroTopSection = {
  additionalContent?: ReactElement
  heroTitle: string
  heroTitleEmphasis: string
  heroByline: string
}

export const HeroTopSection = ({
  additionalContent,
  heroByline,
  heroTitle,
  heroTitleEmphasis,
}: THeroTopSection): ReactElement => {
  return (
    <Box backgroundColor="aubergine">
      <Box
        maxWidth={CONTAINER_MAX_WIDTH}
        mx="auto"
        position="relative"
        zIndex={layers.topSection}
      >
        {additionalContent}
        <Box textAlign="center" color="white" pb={{ base: 8, xl: 36 }} px={36}>
          <EmphasisLinebreakText
            id="homepage-hero-heading"
            as="h1"
            text={heroTitle}
            emphasisText={heroTitleEmphasis}
            variant={{
              base: 'heading-md',
              xl: 'heading-lg',
            }}
            emphasisVariant="heading-lg"
          />
          <Text variant="body-md" id="homepage-hero-subheading">
            {heroByline}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}
