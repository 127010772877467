import { TRow } from '@mindfulchefuk/features/Homepage/components/ComparisonTable/Row'

export const comparisonTableRowInfo: Omit<TRow, 'rowNumber'>[] = [
  {
    text: '100% British and Free-range chicken',
    checked: {},
  },
  {
    text: 'No refined carbs like white pasta, bread or white rice',
    checked: {},
  },
  {
    text: 'No refined sugar and Palm Oil Free',
    checked: {},
  },
  {
    text: '1-person options',
    checked: { gousto: true },
  },
  {
    text: 'All recipes gluten-free',
    checked: {},
  },
  {
    text: 'Every box bought donates one meal to a child in poverty',
    checked: {},
  },
  {
    text: 'Committed to net zero by 2030',
    checked: {},
  },
  {
    text: 'Investing in regenerative farming practices',
    checked: {},
  },
]
