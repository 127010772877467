import React, { ReactElement } from 'react'
import { Box, Grid, Link, Text } from '@mindfulchefuk/design-system'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { Row } from '@mindfulchefuk/features/Homepage/components/ComparisonTable/Row'

import { comparisonTableRowInfo } from '@mindfulchefuk/features/Homepage/components/ComparisonTable/comparisonTableRowInfo'

const COMPARISON_TABLE_SOURCE = process.env.NEXT_PUBLIC_COMPARISON_TABLE_SOURCE

export const RowsGrid = (): ReactElement => (
  <Grid
    data-testid="comparison-table-grid"
    templateColumns="2fr 1fr 1fr 1fr 1fr"
    columnGap={[8, 16]}
    rowGap={12}
    alignItems="center"
    justifyContent="center"
    color="white"
    overflow="none"
    maxHeight="none"
  >
    {comparisonTableRowInfo.map((row, index) => (
      <Row
        checked={row.checked}
        key={row.text}
        text={row.text}
        rowNumber={index}
      />
    ))}
    <Box gridColumn={1}>
      <Text variant={{ base: 'body-sm', sm: 'body-lg' }} mb={32}>
        <Link
          maxWidth={256}
          textAlign="left"
          color="white"
          href={COMPARISON_TABLE_SOURCE}
          rel="noopener noreferrer nofollow"
          target="_blank"
          onClick={() => {
            logEvent({
              category: ANALYTICS_CATEGORIES.homepage,
              action: 'Comparison table clicked',
              label: `View source link clicked`,
            })
          }}
          id="homepage-comparison-table-source-link-text"
        >
          Click here for source
        </Link>
      </Text>
    </Box>
  </Grid>
)
