import React, { ReactElement, useEffect, useState } from 'react'
import {
  Box,
  breakpoints,
  Flex,
  Image,
  Text,
} from '@mindfulchefuk/design-system'
import { isMobileDevice } from '@mindfulchefuk/utils/isMobileDevice'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import CTA from '@mindfulchefuk/features/Homepage/components/CTA/CTA'
import { HeroStaticImage } from '@mindfulchefuk/features/Homepage/components/Hero/HeroStaticImage'
import { useSelector } from 'react-redux'
import { RootState } from '@mindfulchefuk/types/store'
import { getDiscountConfig } from '@mindfulchefuk/utils/getDiscountConfig'
import { genericDiscountCopy } from '@mindfulchefuk/config/voucherConfig'

export type THero = {
  heroImageMobile: string
  heroImageDesktop: string
  trustpilotHeroImage: string
  trustpilotHeroImageMobile: string
  recipeBoxImage: string
}

export const Hero = ({
  heroImageDesktop,
  heroImageMobile,
  trustpilotHeroImage,
  trustpilotHeroImageMobile,
  recipeBoxImage,
}: THero): ReactElement => {
  const [isOnMobileDevice, setIsOnMobileDevice] = useState(false)
  const [imageWidth, setImageWidth] = useState(0)
  const [imageHeight, setImageHeight] = useState(0)

  const heroImage = isOnMobileDevice ? heroImageMobile : heroImageDesktop

  const savedVoucherCode = useSelector(
    (state: RootState) => state.basket.savedVoucherCode
  )
  const discountConfig = getDiscountConfig(savedVoucherCode)

  const trustpilotBanner = isOnMobileDevice
    ? trustpilotHeroImageMobile
    : trustpilotHeroImage

  const getImageSizing = () => {
    const element = document.getElementById('hero-image-container')
    const viewportWidth = window.innerWidth

    if (viewportWidth <= breakpoints.sm) {
      setImageHeight(400)
      setImageWidth(690)
    }

    if (element && viewportWidth >= breakpoints.sm) {
      const { width, height } = element.getBoundingClientRect()

      setImageHeight(height)
      setImageWidth(width)
    }
  }

  useEffect(() => {
    const isOnMobile = isMobileDevice()

    if (isOnMobile) {
      setIsOnMobileDevice(true)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', getImageSizing)
    getImageSizing()
    return () => window.removeEventListener('resize', getImageSizing)
  }, [])

  useEffect(() => {
    window.addEventListener('load', getImageSizing)
    getImageSizing()
    return () => window.removeEventListener('load', getImageSizing)
  }, [])

  return (
    <>
      <Flex
        flexDirection={{ base: 'column-reverse', md: 'row' }}
        data-testid="hero-container"
      >
        <Flex
          flexDirection={{ base: 'column-reverse', md: 'column' }}
          alignItems={{ base: 'center', md: 'start' }}
          bg="salt"
          pl={{ base: 40, md: 112 }}
          pr={{ base: 40, md: 0 }}
          pt={{ base: 24, md: 56 }}
          pb={56}
          width={{ md: '50%' }}
        >
          <Box width={{ base: '90%', md: '35%' }} ml={{ base: 40, md: 0 }}>
            <Image
              alt="Mindful Chef box with vegetables flowing"
              src={recipeBoxImage}
              data-testid="overflowing-box-image"
            />
          </Box>
          <Flex
            flexDirection="column"
            alignItems={{ base: 'center', md: 'start' }}
          >
            <Text
              variant={{ base: 'heading-md', md: 'heading-xl' }}
              width={{ md: '450px' }}
              textAlign={{ base: 'center', md: 'start' }}
            >
              94% of customers say Mindful Chef has made healthy eating easier
            </Text>

            <Text
              variant={{ base: 'body-md', md: 'body-xxxl' }}
              pt={{ base: 8, md: 12 }}
              textAlign={{ base: 'center', md: 'start' }}
            >
              Discover the UK&apos;s #1 rated recipe box
            </Text>

            <Flex width={{ lg: 144 }} mt={{ base: 28, md: 40 }}>
              <CTA
                analytics={{
                  category: ANALYTICS_CATEGORIES.homepage,
                  action: 'Hero CTA button clicked',
                  label: 'Choose Recipes',
                }}
                showCopy={false}
              />
            </Flex>

            <Text
              variant={{ base: 'body-sm', md: 'body-lg' }}
              mt={{ base: 12, lg: 24 }}
              textAlign={{ base: 'center', md: 'start' }}
            >
              Fully flexible subscription, skip or pause at any time
            </Text>
            <Text
              mt={8}
              variant={{ base: 'body-sm', md: 'body-lg' }}
              mb={{ base: 12, lg: 0 }}
            >
              {discountConfig
                ? `Get ${discountConfig.totalDiscountSentence}`
                : genericDiscountCopy}
            </Text>
          </Flex>
        </Flex>
        <HeroStaticImage
          heroImage={heroImage}
          imageHeight={imageHeight}
          imageWidth={imageWidth}
        />
      </Flex>
      <Image
        alt="Mindful Chef TrustPilot rating of 4.5 out of 5 with over 19,000 reviews"
        src={trustpilotBanner}
        data-testid="trustpilot-image"
      />
    </>
  )
}
